'use strict';

var processInclude = require('./util');
var checkoutHelpers = require('./checkout/OneStepCheckoutHelpers');
var verificationPaymentButton = checkoutHelpers.verificationPaymentButton;

$(document).ready(function () {
    setTimeout(function () {
        $('.mask-cel').mask('(00) 00000-0000');
        $('body').find('#checkbox-use-saved-address').click();
    }, 250);

    setTimeout(function () {
        var passengerPanel = $('.passenger__panel');

        passengerPanel.each(function (index, panel) {
            var checkboxParentFirst = $(panel).find('.passenger__own__this__seat:first');
            var checkboxFirst = checkboxParentFirst.find('input:checkbox');
            var checkboxFirstParents = checkboxFirst.closest('.form-group');
            var inputsGroup = checkboxFirstParents.find('input');

            var checkboxParentOthers = $(panel).find('.passenger__own__this__seat:not(:first)');
            var checkboxOthers = checkboxParentOthers.find('input:checkbox');

            $(checkboxOthers).prop('disabled', true);
            $(checkboxParentOthers).css('opacity', '0');

            if (inputsGroup.val().length < 1) {
                checkboxFirst.click();
            }
        });
    }, 350);

    setTimeout(function () {
        var iscardNumberSaved = $('#cardNumber').is(':disabled') || $('#cardNumber').prop('readonly');
        var iscardfirstNameSaved = $('#checkout-form-firstName').is(':disabled') || $('#checkout-form-firstName').prop('readonly');
        var iscardExpirationMonthSaved = $('#expirationMonth').is(':disabled') || $('#expirationMonth').prop('readonly');
        var iscardExpirationYearSaved = $('#expirationYear').is(':disabled') || $('#expirationYear').prop('readonly');

        if (iscardNumberSaved === true && iscardfirstNameSaved === true && iscardExpirationMonthSaved === true && iscardExpirationYearSaved === true) {
            $('#securityCode').removeAttr('required');
            $('#isSavedCardUsed').val(true);
        }
    }, 350);

    $('.new-card-option').on('click', function () {
        $('#isSavedCardUsed').val(false);
        $('.new-card-option').css('display', 'none');
        $('.payment__card__info').css('display', 'block');
        $('.save-credit-card').css('display', 'block');
        $('.save-credit-card-buttom').removeClass('d-none');
        $('#securityCode').css('display', 'block');
        $('#cardNumber, #checkout-form-firstName, #expirationMonth, #expirationYear')
            .removeAttr('readonly disabled')
            .val('')
            .removeClass('is-invalid');
        $('#installment').css('display', 'none');
        $('.saved-card-message').css('display', 'none');
        $('#btnSubmitPayment').text('Comprar');
        $('.saved-card-option').removeClass('d-none');
        $('#saved_cards').css('display', 'none');
        $('.selected-payment').removeClass('selected-payment');
        $(".expirationMonth option[value='**']").remove();
        $(".expirationYear option[value='****']").remove();
    });

    $('#payments-credit-tab').on('click', function () {
        $('#cardNumber').val($('.selected').find('.saved-payment-instrument').data('card'));
        $('.selected-payment').click();
    });

    $('.saved-card-option').on('click', function () {
        $('#securityCode').css('display', 'none');
        $('#isSavedCardUsed').val(true);
        $('.save-credit-card').css('display', 'none');
        $('.payment__card__info').css('display', 'none');
        $('.new-card-option').css('display', 'block');
        $('#installment').css('display', 'block');
        $('.saved-card-message').css('display', 'block');
        $('#btnSubmitPayment').text('Comprar com 1 clique');
        $('.saved-card-option').addClass('d-none');
        $('#saved_cards').css('display', 'block');
        $('.new-card-option').css('display', 'block');

        $('#cardNumber').val($('.selected').find('.saved-payment-instrument').data('card'));
        $('#btnSubmitPayment').html('Comprar com 1 clique');
    });

    processInclude(require('./cart/cart'));
    $('[data-toggle=tab]').on('show.bs.tab', function () {
        var paymentRoot = $('#paymentRoot');
        if (this.id === 'paymentDebit') {
            setTimeout(() => {
                $('#btnSubmitPayment').prop(
                    'innerText',
                    $('#btnSubmitPayment').data('debit-text')
                );
                $('#paymentMethod').val('CIELO_DEBIT_CARD');
                $('#securityCode').val('000');
                $('.credit-securityCode').hide();
                $('.credit-installments').children().hide();
                $('#not-saved-payment-instrument').click();
                $('#saved_cards').children().hide();

                paymentRoot.attr('data-mode', 'debit');
                paymentRoot.find('.save-credit-card').hide();
                $('.bpmpi_paymentmethod').val('debit');
                $('body')
                    .find('#installment__value')
                    .parent()
                    // eslint-disable-next-line quote-props
                    .css({ 'display': 'none', 'margin-left': '10rem' });
                $('#paymentDebitDiv').append(paymentRoot);
            }, 200);
        } else {
            setTimeout(() => {
                $('#btnSubmitPayment').prop(
                    'innerText',
                    $('#btnSubmitPayment').data('credit-text')
                );
                $('#paymentMethod').val('CIELO_CREDIT_CARD');
                $('#securityCode').val('');
                $('.credit-securityCode').show();
                $('.credit-installments').children().show();
                $('#saved_cards').children().show();

                paymentRoot.attr('data-mode', 'credit');
                paymentRoot.find('.save-credit-card').show();
                $('.bpmpi_paymentmethod').val('credit');

                $('body')
                    .find('#installment__value')
                    .parent()
                    .css('display', 'block');

                $('#paymentCreditDiv').append(paymentRoot);
            }, 200);
        }
    });
});

$('#btnSubmitPayment').on('click', function () {
    setTimeout(function () {
        var isInvalidSecurityText = $('#securityCode').hasClass('is-invalid');

        if (isInvalidSecurityText) {
            $('.payment__card__info').css('display', 'block');
            $('#securityCode').css('display', 'block');
            $('#isSavedCardUsed').val(true);
            window.scrollTo(0, document.getElementsByClassName('payment__card__info')[0].offsetTop);
            $('body').trigger('checkoutLoading:stop');
        }
    }, 1200);
});

$('body').ready(function () {
    setTimeout(function () {
        if ($('.select__list').length > 0) {
            $('#isSavedCardUsed').val(true);
            $('.payment__card__info').css('display', 'none');
            $('.new-card-option').css('display', 'block');
        } else {
            $('#isSavedCardUsed').val(false);
            $('.payment__card__info').css('display', 'block');
            $('.new-card-option').css('display', 'none');
        }
    }, 250);
});

$('.trip-wrap').ready(function () {
    var empty = $('.passenger__input-document, .passengerName').filter(function () {
        return $(this).val().trim() === '';
    });

    var fullName = $('.passengerName').filter(function () {
        var name = $(this).val().trim();
        return name.split(/\s+/).length < 2 || !/^[A-Za-zÀ-ÖØ-öø-ÿ\s]+$/.test(name);
    });

    if (empty.length === 0 && fullName.length === 0) {
        $('#pix-buy-button').prop('disabled', false);
        $('.submit-credit').prop('disabled', false);
        $('.submit-debit').prop('disabled', false);
    }
});

$('.passengerName, .passenger__input-document, .checkbox-use-saved-profile, .select-passenger,.ticket__ImPassenger').on('input', function () {
    verificationPaymentButton();
});
