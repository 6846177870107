/* eslint-disable no-alert */

'use strict';

var salesForceDataHelper = require('../../../../scripts/helpers/salesForceDataHelper');
var formHelpers = require('./formErrors');
var gtm = require('../gtm/gtmScript');
var gtmHelper = require('../../../../scripts/helpers/gtmHelper');
var toggleModal = require('../components/toggleModal');
const { getArrayParams, getArrayParamsFromInputs } = require('../amplitude/amplitudeHelpers');

var displayCardErrors = function (form, data, selector) {
    var cardNumberName = $('.cardNumber', form).prop('name');
    var errorObj = {};
    errorObj[cardNumberName] = data.errorMessage.replace('[Isa]', '<img src="' + data.chatBotImg + '" style="width:3em; height:3em;" alt="Isa" title="Isa">');

    formHelpers.loadFormErrors(selector, errorObj);

    $('input[type="text"]').on('click', function () {
        $('.cardNumber').removeClass('is-invalid');
    });
    $('select').on('click', function () {
        $('.cardNumber').removeClass('is-invalid');
    });
};

var distribusionPooling = function (data) {
    console.info('Start Pooling %s / place order success');
    var continuePooling = true;
    var UrlPooling = data.urlPooling;
    if (continuePooling) {
        $.ajax({
            url: UrlPooling,
            method: 'GET',
            success: function (resp) {
                if (!resp.continuePooling) {
                    continuePooling = false;
                    console.info('Parando o pooling....');
                }
                if (continuePooling) {
                    console.info('Continuando o pooling...');
                    setTimeout(function () {
                        distribusionPooling(data);
                    }, data.poolingInterval);
                }

                if (resp.continueUrl) {
                    var continueUrl = resp.continueUrl + (resp.continueUrl.indexOf('?') !== -1 ? '&' : '?') + 'ID=' + encodeURIComponent(data.orderID) + '&token=' + encodeURIComponent(data.orderToken);
                    window.location.href = continueUrl;
                }

                if (!resp.continuePooling && resp.timeout) {
                    $('body').trigger('checkoutLoading:stop');
                    toggleModal.showError({ title: 'Enfrentando lentidão', message: 'Seu pedido está demorando mais do que o esperado, enviaremos a confirmação por e-mail.', btnHome: true });
                }
            },
            error: function () {
                console.error('Ocorreu um erro durante a requisição.');
            }
        });
    }
};

function mountedPassengerData() {
    const $passengersNames = $('.form-control.passengerName');
    const $passengersDocumentType = $('.form-select.form-select-lg.select-document-passenger');
    const $passengersDocumentValue = $('.form-control.passengerDoc.passenger__input-document');
    const $passengersSeatNumbers = $('.card-seat-number.d-flex span');

    const passengers = [];
    $passengersNames.each((i, passenger) => {
        const nameArray = passenger.value.split(' ');
        let seats = [
            {
                segment_index: 0,
                seat_code: $passengersSeatNumbers[i].textContent
            }
        ];
        passengers.push({
            first_name: nameArray[0],
            last_name: nameArray[nameArray.length - 1],
            type: 'PNOS', // FIXME: hard coded
            government_id_type: $passengersDocumentType[i].value,
            government_id: $passengersDocumentValue[i].value,
            seats: JSON.stringify(seats)
        });
    });
    return passengers;
}

/**
 * include checkout to dataLayer with step and option
 */
var checkoutConfirmation = function () {
    var element = $('.ticket__card');
    var eventObject = gtmHelper.createRequestObject(element, 'checkoutConfirmation');
    if (eventObject) {
        gtm.executePush('checkout', eventObject);
        salesForceDataHelper.updateSalesForceData(null, 'checkout', eventObject);
    }
};

function refresh3DSAuth(orderNo) {
    if (orderNo) {
        $('.bpmpi_ordernumber').val(orderNo);
    }
    $('.bpmpi_billto_customerid').val($('#checkout-form-cpffield').val().replace(/[^0-9]/g, ''));
    $('.bpmpi_billto_phonenumber').val($('#checkout-form-telefonefield').val().replace(/[^0-9]/g, ''));
    $('.bpmpi_billto_zipcode').val($('#checkout-form-cep').val().replace(/[^0-9]/g, ''));
    $('.bpmpi_billto_street1').val($('#checkout-form-address').val() + ', ' + $('#checkout-form-number').val());
    $('.bpmpi_cardnumber').val($('#cardNumber').val().replace(/[^0-9]/g, ''));
    $('.bpmpi_cardexpirationmonth').val(($('#expirationMonth').val().length < 2 ? '0' : '') + $('#expirationMonth').val());
    $('.bpmpi_cardexpirationyear').val($('#expirationYear').val());
    // eslint-disable-next-line no-undef
    bpmpi_authenticate();
}

function placeOrder(mode, billingForm, url) {
    var sendDebitInfo = false;
    var orderParams = '?p=ok';

    var passengers = mountedPassengerData();
    orderParams += '&passengersData=' + JSON.stringify(passengers);

    console.info('Submit %s / place order...', mode);

    if ($('.bpmpi_ordernumber').val() && $('.bpmpi_ordernumber').val() != '001') {
        console.info('Submit %s / place order with order number %s', mode, $('.bpmpi_ordernumber').val());
        orderParams += '&orderNo=' + $('.bpmpi_ordernumber').val();
    }
    if (mode == 'debit' && $('.bpmpi_auth').data('auth-cavv')) {
        console.info('Submit %s / place order with auth-cavv %s', mode, $('.bpmpi_auth').data('auth-cavv'));
        sendDebitInfo = true;
        orderParams += '&orderAuth=' + $('.bpmpi_auth').data('auth-cavv');
    }
    $('body').trigger('checkoutLoading:init');

    $.ajax({
        url: url + orderParams,
        data: {
            number: sendDebitInfo ? $('#cardNumber').val().replace(/[^0-9]/g, '') : ''
        },
        method: 'POST',
        success: function (data) {
            if (data.errorFraudDetection || data.errorCCBuyAttempts) {
                $('#payments-pix-tab').trigger('click');
                toggleModal.show('#showPixAnti');
                $('body').trigger('checkoutLoading:stop');
            } else if (data.distribusionPooling) {
                distribusionPooling(data);
            } else if (!data.error) {
                console.info('Submit %s / place order success', mode);
                checkoutConfirmation();
                var continueUrl = data.continueUrl + (data.continueUrl.indexOf('?') !== -1 ? '&' : '?') + 'ID=' + encodeURIComponent(data.orderID) + '&token=' + encodeURIComponent(data.orderToken);
                window.location.href = continueUrl;
            } else {
                $('.bpmpi_ordernumber').val('001');
                if (data.errorMessage) {
                    $('.card-error').html(data.errorMessage);
                    $('.card-error').removeClass('d-none');
                }
                if (data.cartError) {
                    console.error('Submit %s / place order error (cart)', mode);
                    window.location.href = data.redirectUrl;
                } else {
                    console.info('Submit %s / place order error (not cart)', mode);
                    $('body').trigger('checkoutLoading:stop');

                    if (data.errorTB) {
                        alert('Compra não finalizada! A poltrona(s) selecionada não está mais disponível, selecione outra');

                        window.location.href = data.ticketURL;
                    }
                    displayCardErrors(billingForm, data, $('.payment__card__info'));

                    if (data.braspagTryNo > 3) {
                        $('.helpButtonEnabled').trigger('click');

                        setTimeout(() => {
                            $('.embeddedServiceSidebarForm input#FirstName').val(data.profile.firstName);
                            $('.embeddedServiceSidebarForm input#LastName').val(data.profile.lastName);
                            $('.embeddedServiceSidebarForm input#Email').val(data.profile.email);
                            $('.embeddedServiceSidebarForm input#Phone').val(`(${data.profile.com_telephoneDDD}) ${data.profile.phone}`);
                            $('.embeddedServiceSidebarForm input#Subject').val('Não consigo finalizar a compra (3)');
                        }, 4000);
                    }
                }
            }
        },
        error: function () {
            $('.bpmpi_ordernumber').val('001');
            $('body').trigger('checkoutLoading:stop');

            console.error('Submit %s / place order (server error)', mode);
        }
    });
}
function validatePassengerFieldsPix(hasError, errorMessage) {
    $('.passenger__panel input[type="text"]').each(function (index, e) {
        var regex = /^[\w\s]+$/;
        if (hasError && !regex.test(e.value)) {
            e.classList.add('is-invalid');
            // eslint-disable-next-line no-param-reassign
            e.nextElementSibling.innerHTML = errorMessage;
            return;
        }
        e.classList.remove('is-invalid');
    });
}
function updatePassengersInfo() {
    const $passengerInfo = $('#passenger-info');
    const $passengerInfoPix = $('#passenger-info-pix');
    const $passengersBox = $('.passenger-box');

    var passengersData = $passengersBox.map((_index, passenger) => {
        return {
            uuid: $(passenger).attr('data-uuid'),
            formName: $(passenger).find('.passengerName').attr('name'),
            formNameDoc: $(passenger).find('.passengerDoc').attr('name'),
            passengerName: $(passenger).find('.passengerName').val(),
            documentType: $(passenger).find('.select-document-passenger').val(),
            passengerID: ($(passenger).find('.passengerDoc').val())
                .replaceAll('.', '')
                .replaceAll('-', '')
                .replaceAll('/', ''),
            savePassenger: $(passenger).find('.checkbox-saved-profile').is(':checked'),
            departureTime: $(passenger).attr('data-departure-time'),
            serviceNumber: $(passenger).attr('data-service-number'),
            seatNumber: $(passenger).attr('data-seat-number')
        };
    });

    const passengerDataString = JSON.stringify(passengersData.toArray());
    $passengerInfo.val(passengerDataString);
    $passengerInfoPix.val(passengerDataString);
}

function arrayToQueryString(array) {
    return array.map(obj => {
        const params = new URLSearchParams();
        // eslint-disable-next-line no-restricted-syntax
        for (let key in obj) {
            // eslint-disable-next-line no-prototype-builtins
            if (obj.hasOwnProperty(key)) {
                params.append(key, obj[key]);
            }
        }

        return params.toString();
    }).join('&');
}

function queryString(obj) {
    const params = new URLSearchParams();
    // eslint-disable-next-line no-restricted-syntax
    for (let key in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(key)) {
            params.append(key, obj[key]);
        }
    }

    return params.toString().join('&');
}

function submitPayment() {
    updatePassengersInfo();
    console.info('Submit payment...');
    var mode = $('#paymentRoot').data('mode');
    var selector = 'form[name$=billing]';
    var billingForm = $(selector);

    var formData = billingForm.serialize();
    formData += '&serviceTax=' + $('#serviceTax').val();

    if ($('#paymentMethod').val() == 'CIELO_CREDIT_CARD' && $('#saveCreditCard').is(':checked') == true) {
        var numberCardInput = $('input.cardNumber').val().slice(-4);
        var isSameCard = false;

        $('.select__list .saved-payment-instrument').each(function () {
            if ($(this).data('card').replaceAll('*', '') == numberCardInput) {
                alert('O cartão já está salvo!');
                $('#saveCreditCard').trigger('click');
                isSameCard = true;
            }
        });
        if (isSameCard) {
            return false;
        }
    }

    if ($('.selected-payment').length > 0) {
        formData += '&storedPaymentUUID=' + $('.selected-payment').data('uuid');

        if (mode == 'credit') {
            formData += '&securityCode=' + $('.securityCode', billingForm).val();
        }
    }

    var passengers = mountedPassengerData();
    formData += '&passengersData=' + JSON.stringify(passengers);

    $('body').trigger('checkoutLoading:init');
    $.ajax({
        url: billingForm.data('action') + '?isPasted=' + $('#isPasted').data('flag'),
        type: 'POST',
        dataType: 'json',
        data: formData,
        success: function (data) {
            console.info('🚀 ~ file: OneStepCheckoutHelpers.js:234 ~ submitPayment ~ data', data);
            var errorCount;
            var maxErrorCount;

            if (!data.error) {
                console.info('%cSubmit %s success', 'background: #4BDB0C; color: #000', mode);
                if (mode == 'debit' && (!$('.bpmpi_ordernumber').val() || $('.bpmpi_ordernumber').val() == '001')) {
                    $.ajax({
                        url: $('.bpmpi_ordernumber').data('url'),
                        type: 'GET',
                        success: function (retOrder) {
                            if (retOrder.success) {
                                refresh3DSAuth(retOrder.newOrderNo);
                            }
                        },
                        error: function () {
                            $('#cardNumberInvalidMessage').html('Ocorreu um erro ao iniciar a autenticação');
                            $('#cardNumberInvalidMessage').show();
                        }
                    });
                } else if (mode == 'debit' && !$('.bpmpi_auth').data('auth-new')) {
                    refresh3DSAuth();
                } else {
                    $('.bpmpi_auth').data('auth-new', false);
                    placeOrder(mode, billingForm, data.continueUrl, selector);
                }
            } else if (data.errorStage && data.errorMessage) {
                $('body').trigger('checkoutLoading:stop');

                $('#creditErrorCount').val(Number($('#creditErrorCount').val()) + 1);
                errorCount = Number($('#creditErrorCount').val());

                maxErrorCount = $('#creditErrorCount').data('max-attempts');
                if (errorCount == Number(maxErrorCount)) {
                    $('#payments-pix-tab').trigger('click');
                    toggleModal.show('#showPixCCError');
                } else if (data.errorStage.stage == 'payment') {
                    displayCardErrors(billingForm, data, '.payment__card__info');
                }
            } else if (!data.redirectUrl && data.fieldErrors) {
                $('body').trigger('checkoutLoading:stop');
                $('#creditErrorCount').val(Number($('#creditErrorCount').val()) + 1);
                errorCount = Number($('#creditErrorCount').val());

                maxErrorCount = $('#creditErrorCount').data('max-attempts');
                if (errorCount == Number(maxErrorCount)) {
                    $('#payments-pix-tab').trigger('click');
                    toggleModal.show('#showPixCCError');
                } else if (data.errorStage && data.errorStage.stage == 'payment') {
                    selector = '.payment__card__info';
                }
                formHelpers.loadFormErrors(selector, data.fieldErrors);
            } else {
                $('#creditErrorCount').val(Number($('#creditErrorCount').val()) + 1);
                errorCount = Number($('#creditErrorCount').val());
                maxErrorCount = $('#creditErrorCount').data('max-attempts');

                if (errorCount == Number(maxErrorCount)) {
                    $('#payments-pix-tab').trigger('click');
                    toggleModal.show('#showPixCCError');
                }

                $('.new-card-option').css('display', 'block');
                $('body').trigger('checkoutLoading:stop');
            }
        },
        error: function () {
            console.error('%c Submit %s fail', 'background: #DB1F0C; color: #000', mode);
            var errorCount = Number($('#creditErrorCount').val());
            if (errorCount == 3) {
                $('#payments-pix-tab').trigger('click');
                toggleModal.show('#showPixCCError');
            }
            $('body').trigger('checkoutLoading:stop');
        }
    });
}

var changePaymentMode = function () {
    $('.payment__options .nav-link').on('click', function (e) {
        e.preventDefault();
        var href = e.delegateTarget.getAttribute('href');
        $('#paymentRoot').data('mode', href.split('#')[1]);
    });
};

var maskPassengerDocuments = function () {
    var selectedDocument = $('.select-document-passenger');

    $(selectedDocument).each(function (_index, passenger) {
        const selectedValue = $(passenger).val();
        const inputDocument = $(passenger).closest('.passenger-form').find('.passengerDoc');

        switch (selectedValue) {
            case 'Documento':
                $(inputDocument).unmask();
                $(inputDocument).attr('disabled', 'disabled');
                break;
            case 'CPF':
                $(inputDocument).unmask();
                $(inputDocument).mask('000.000.000-00');
                break;
            case 'RG':
                $(inputDocument).unmask();
                $(inputDocument).mask('00.000.000-0');
                break;
            default:
                $(inputDocument).unmask();
                break;
        }
    });
};
function checkboxDataPassenger() {
    var checkbox = $(this);
    var passengerName;
    var passengerID;
    var docType;

    if (this.checked) {
        var url = $(this).attr('data-url');
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                if (!data.error) {
                    passengerName = data.firstName + ' ' + data.lastName;

                    if (data.cpf === null || data.cpf === '') {
                        if (data.rg === null || data.rg === '') {
                            passengerID = data.passport;
                            docType = 'Passaporte';
                        } else {
                            passengerID = data.rg;
                            docType = 'RG';
                        }
                    } else {
                        passengerID = data.cpf;
                        docType = 'CPF';
                    }

                    const passengerBox = $('.ticket__ImPassenger').closest('.passenger-box');
                    const saveProfileButton = $(passengerBox).find('.checkbox-use-saved-profile');
                    const passengerNameInput = $(passengerBox).find('.passengerName');
                    const passengerDocType = $(passengerBox).find('.select-document-passenger');
                    const passengerDoc = $(passengerBox).find('.passengerDoc');

                    $(passengerDoc).unmask();
                    $(passengerNameInput).val(passengerName);
                    $(passengerDocType).val(docType);
                    $(passengerDoc).val(passengerID);

                    $(passengerNameInput).attr('disabled', 'disabled');
                    $(passengerDocType).attr('disabled', 'disabled');
                    $(passengerDoc).attr('disabled', 'disabled');

                    saveProfileButton.removeAttr('disabled');
                    maskPassengerDocuments();
                } else {
                    checkbox.prop('checked', false);
                }
            },
            onError: function () {
                checkbox.prop('checked', false);
            }
        });
    }
}

function completeInputCard() {
    $('.expirationMonth').append(`<option value="${'**'}">
        ${'**'}
    </option>`);
    $('.expirationMonth').prop('disabled', true).val('**');
    $('.expirationYear').append(`<option value="${'****'}">
        ${'****'}
    </option>`);
    $('.expirationYear').prop('disabled', true).val('****');
    $('.saved-payment-security-code').val('');
}

function modifyPaymentArea() {
    const navlinks = $('.nav-link');
    $('#payments-tabContent').removeClass('section-wrapper');
    $(navlinks).each(function (_index, item) {
        const tabID = $(item).data('target');
        const tabsWarp = $(tabID);
        $(tabID).before('<div class="section-wrapper mb-4 p-4"></div>');
        $(tabID).prev().append(tabsWarp);
        $(tabID).before(item);
    });

    let allTabs = $('#payments-tabContent').children();

    allTabs.each(function () {
        $('.badge-checkout').addClass('active');
    });

    $('.badge-checkout').unbind().on('click', function (e) {
        e.preventDefault();

        let targetBox = $(this).data('target');
        $(targetBox).toggleClass('active show');
        $(this).find('.text').toggleClass('bold');
        $(this).find('.arrow').toggleClass('rotated');

        allTabs.each(function (index, item) {
            let idTarget = $(item).find('.badge-checkout').data('target');

            if (targetBox != idTarget) {
                $(idTarget).parent().children(idTarget).removeClass('active show');
                $(item).find('.text').removeClass('bold');
                $(item).find('.arrow').removeClass('rotated');
            }
        });
    });
}

function cleanInputCard() {
    $('.cardNumber').prop('disabled', false);
    $('.cardNumber').val('');
    $('#checkout-form-firstName').prop('disabled', false);
    $('#checkout-form-firstName').val('');
    $('.expirationMonth').prop('disabled', false);
    $(".expirationMonth option[value='**']").remove();
    $('.expirationMonth').val('');
    $('.expirationYear').prop('disabled', false);
    $(".expirationYear option[value='****']").remove();
    $('.expirationYear').val('');
    $('.saved-payment-security-code').val('');
}

function changePositionSummary() {
    const summaryMobile = $('.order-summary-mobile');
    $(summaryMobile).append($('.order-summary'));
}

function verificationPaymentButton() {
    setTimeout(function () {
        var empty = $('.passenger__input-document, .passengerName').filter(function () {
            return $.trim($(this).val()) === '';
        });

        var fullName = $('.passengerName').filter(function () {
            var name = $(this).val().trim();
            return name.split(/\s+/).length < 2 || !/^[A-Za-zÀ-ÖØ-öø-ÿ\s]+$/.test(name);
        });
        if (empty.length === 0 && fullName.length === 0) {
            $('#pix-buy-button').prop('disabled', false);
            $('.submit-credit').prop('disabled', false);
            $('.submit-debit').prop('disabled', false);
        } else {
            $('#pix-buy-button').prop('disabled', true);
            $('.submit-credit').prop('disabled', true);
            $('.submit-debit').prop('disabled', true);
        }
    }, 400);
}

function showInputsErrors(inputs) {
    console.info('🚀 ~ file: OneStepCheckoutHelpers.js:432 ~ showInputsErrors ~ inputs:', inputs);
    inputs.toArray().forEach(function (input) {
        $(input).addClass('is-invalid');

        var name = $(input).val().trim();
        if ($(input).hasClass('passengerName') && (name.split(/\s+/).length < 2 || !/^[A-Za-zÀ-ÖØ-öø-ÿ\s]+$/.test(name))) {
            $(input).parent().find('.invalid-feedback').text('Favor inserir nome e sobrenome sem caracteres especiais e números')
                .show();
        } else {
            $(input).parent().find('.invalid-feedback').text('Campo Obrigatório')
                .show();
        }
        $(input).on('input', function () {
            $(this).removeClass('is-invalid');
            $(this).parent().find('.invalid-feedback').hide();
        });
    });
}

function getAmplitudeCheckoutIniciado() {
    var tipoViagem = $('.tripType-Volta').length > 0 ? 'Ida e Volta' : 'Ida';
    var eventProperties = {
        cidade_destino: getArrayParams('city-destination'),
        cidade_origem: getArrayParams('city-origin'),
        confirmacao_estudante: $('.passenger-form').data('student'),
        confirmacao_pcd: $('.passenger-form').data('pcd'),
        data_partida: getArrayParamsFromInputs('departure-date'),
        tipo_documento: $('.select-document-passenger').val(),
        tipo_viagem: tipoViagem,
        uf_destino: getArrayParamsFromInputs('uf-destination'),
        uf_origem: getArrayParamsFromInputs('uf-origin'),
        valor_total_compra: +$('.total-area').data('subtotalpricenonformatted')
    };

    return eventProperties;
}

module.exports = {
    placeOrder: placeOrder,
    submitPayment: submitPayment,
    checkoutConfirmation: checkoutConfirmation,
    changePaymentMode: changePaymentMode,
    maskPassengerDocuments: maskPassengerDocuments,
    checkboxDataPassenger: checkboxDataPassenger,
    completeInputCard: completeInputCard,
    updatePassengersInfo: updatePassengersInfo,
    modifyPaymentArea: modifyPaymentArea,
    validatePassengerFieldsPix: validatePassengerFieldsPix,
    cleanInputCard: cleanInputCard,
    changePositionSummary: changePositionSummary,
    verificationPaymentButton: verificationPaymentButton,
    showInputsErrors: showInputsErrors,
    arrayToQueryString: arrayToQueryString,
    queryString: queryString,
    mountedPassengerData: mountedPassengerData,
    getAmplitudeCheckoutIniciado: getAmplitudeCheckoutIniciado
};
